import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Button from "ab-form-button";
import Link from "ab-link";
import Text from "ab-text";
import Input from "ab-input";
import { useUserContext } from "ab-context-user";
import { gtmSendLogin } from "ab-gtm";

import { postLogin } from "./methods";
import "./ab-login.scss";

const Login = ({
  registerText,
  formTitle,
  rememberMe,
  forgotPassword,
  emailPlaceholder,
  passwordPlaceholder,
  locale,
  isFromPedido,
  i18n,
}) => {
  const router = useRouter();
  const [state, dispatch] = useUserContext();
  const { loginUser } = dispatch;

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common", { i18n });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email:
        router?.query?.email ||
        "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("validationEmail"))
        .required(t("validationEmail")),
      password: Yup.string().required(t("validationPassword")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let loginError = await loginUser(
        values.email,
        values.password,
        i18n.language || locale,
        values.rememberMe,
        router?.query?.action === "finalizar"
      );
      // console.log("loginError ", loginError);
      if (loginError) {
        setError(loginError);
      } else {
        gtmSendLogin();

        postLogin(values.email);
      }

      if (router?.query?.action === "finalizar") {
        router.push(t("/finalizar-compra"));
      }
      setLoading(false);
    },
  });

  const { errors, values, setFieldValue, handleSubmit } = formik;

  // console.log("Login", router);
  // console.log("Login error", error);
  return (
    <div className="ab-login">
      <form onSubmit={handleSubmit}>
        <Text element="h3">{formTitle || t("formTitle")}</Text>
        <Input
          placeholder={emailPlaceholder || t("emailPlaceholder")}
          value={values.email}
          error={errors.email}
          onChange={(val) => setFieldValue("email", val)}
        />
        <Input
          placeholder={passwordPlaceholder || t("passwordPlaceholder")}
          value={values.password}
          error={errors.password}
          onChange={(val) => setFieldValue("password", val)}
          type="password"
        />

        <Input
          label={rememberMe || t("rememberMe")}
          type="checkbox"
          value={values.rememberMe}
          onChange={(val) => setFieldValue("rememberMe", val)}
        />

        {error ? <div className="error">{error}</div> : null}

        <Button loading={loading} onClick={handleSubmit}>
          {t("acceder")}
        </Button>

        <Link
          prefetch={false}
          text={forgotPassword || t("forgotPassword")}
          href={t("/recuperar-contrasena")}
        />
      </form>

      <div className="right">
        {isFromPedido ? (
          <>
            <Text element="h3">{t("thanks")}</Text>
            <Text
              element="h4"
              className="gold"
              style={{ marginTop: 19, width: "100%", maxWidth: 590 }}
            >
              {`${isFromPedido}`}
            </Text>
            <Text
              element="span"
              className="title"
              style={{ marginTop: 8, width: "100%", maxWidth: 590 }}
            >
              {t("yourOrder")}
              {t("yourOrder2")}
            </Text>

            <Text
              element="span"
              className="title"
              style={{ marginTop: 8, width: "100%", maxWidth: 590 }}
            >
              {t("logIn")}
            </Text>
          </>
        ) : (
          <>
            <Text element="h3">{registerText || t("registerText")}</Text>
            <Link
              prefetch={true}
              text={t("createAccount")}
              href={t("/registro")}
            ></Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
