const API_URL = process.env.API_URL;

export async function postLogin(email) {
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.X_API_KEY,
  };

  // console.log("headers", headers);
  let query = `
    mutation postLogin($userEmail: String!) {
        postLogin(userEmail: $userEmail )     
    }
  
    `;

  // console.log("postLogin API_URL", API_URL);
  const res = await fetch(API_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables: { userEmail: email },
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      // console.log("postLogin", result);
    })
    .catch((err) => {
      // console.log("postLogin - catch", err);
    });
}
