import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { getPage } from "ab-api-queries";
import ABLogin from "ab-login";

const Login = ({ content }) => {
  const router = useRouter();
  const { i18n } = useTranslation("common");
  console.log(
    "pageProps",
    content?.find(
      (itm) => itm.fieldGroupName == "Page_Contenidoflexible_Modulos_Hero"
    )
  );

  return (
    <ABLogin
      locale={i18n.language}
      isFromPedido={
        router.query.action &&
        router.query.action !== "finalizar" &&
        router.query.action
      }
      i18n={i18n}
    />
  );
};

export async function getServerSideProps({ locale }) {
  const data = await getPage("/login");

  // const locale = getLocaleFromContext(context);
  // const redirectURL = getLinkFromTranslation(data?.nodeByUri?.translations, locale);

  // if (locale != 'es' && redirectURL) {
  //   return {
  //     redirect: {
  //       permanent: false,
  //       destination: redirectURL
  //     }
  //   };
  // }

  const translations = await serverSideTranslations(locale, ["common"]);

  return {
    props: {
      seo: data?.nodeByUri?.seo || null,
      content: data?.nodeByUri?.contenidoFlexible?.modulos || [],
      footer: data?.headerFooter?.footer,
      menu: data?.headerFooter?.menu,
      translations: data?.nodeByUri?.translations,
      language: data?.nodeByUri?.language,
      ...translations,
      // session: footerData?.session,
    }, // will be passed to the page component as props
  };
}

export default Login;
